.summary_block p{
    margin: 0;
	/* padding-left: 20px; */
	font-size: 14px;
	color: var(--color-dark-grey);
    margin-bottom: 10px;

}
.summary_block{
    padding: 10px 0px 0px 0px;
    border-bottom: 1px solid var(--color-grey);
} 
.summary_block:last-child{
    border-bottom: none; 
}
.participant_count{
    font-weight: bold;
}