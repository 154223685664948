.sidebar {
	width: 225px;
	flex-shrink: 0;
}

.sidebar__logo {
	margin-top: 10px;
	margin-bottom: 15px;
}

.sidebar__nav {
	padding: 10px;
}
.toggle__close{
	display: none;
}

.sidebar__nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sidebar__nav ul li {
	margin-bottom: 10px;
}

.sidebar__nav .sidebar__nav-link {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 10px 15px;
	border-radius: 4px;
	color: var(--color-white);
	text-decoration: none;
	font-size: 16px;
	line-height: 1.2;
	font-weight: 500;
}

.sidebar__nav .sidebar__nav-link:hover {
	color: var(--color-yellow);
}

.sidebar__nav .sidebar__nav-link.active {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

@media screen and (max-width: 800px) {
	.sidebar {
        width: 225px;
        height: 100%;
        position: fixed;
        z-index: 999;
        background-color: var(--color-very-dark-grey);
        top: 0%;
    }
	.sidebar.closed{
		display: none;
	}
	/* .sidebar__logo{
		
	} */
	.toggle__close{
		margin-bottom: 0px;
		display: block;
		text-align: right;
		margin-top: 20px;
		padding-right: 10px;
	}
	.toggle__close p{
		font-size: 25px;
		cursor: pointer;
		color: var(--color-white);
		margin: 0px;
		margin-top: 20px;
		margin-right: 15px;
	}

}
