.waitingLoader__section {
	border: 1px solid #d1d1d1;
	padding: 30px 20px;
	border-radius: 8px;
	margin-bottom: 20px;
}

.waitingLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	font-weight: 600;
}

.waitingLoader__icon {
	animation: hourglass 1s linear infinite;
}

.waitingLoader__icon .hourglass {
	display: block;
	/* background: #f7f7f7; */
	width: 12px;
	height: 24px;
}

.waitingLoader__icon .hourglass .outer {
	fill: #bcbdbd;
}

.waitingLoader__icon .hourglass .middle {
	fill: #f7f7f7;
}

@keyframes hourglass {
	0% {
		transform: rotate(0deg);
		box-shadow: inset #bcbdbd 0 0em 0 0, inset #dad8d2 0 -12px 0 0,
			inset #bcbdbd 0 -24px 0 0;
	}
	80% {
		transform: rotate(0deg);
		box-shadow: inset #bcbdbd 0 -12px 0 0, inset #dad8d2 0 -12px 0 0,
			inset #bcbdbd 0 -12px 0 0;
	}
	100% {
		transform: rotate(180deg);
		box-shadow: inset #bcbdbd 0 -12px 0 0, inset #dad8d2 0 -12px 0 0,
			inset #bcbdbd 0 -12px 0 0;
	}
}
