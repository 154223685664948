.theme {
	border: 1px solid var(--color-very-light-grey);
	background-color: var(--color-white);
	border-radius: 8px;
	padding: 15px;
	margin-bottom: 20px;
}

.theme__title {
	margin-bottom: 10px;
}

.theme ul {
	margin: 0;
	padding-left: 20px;
	font-size: 14px;
	color: var(--color-dark-grey);
}

.theme ul li {
	margin-bottom: 12px;
}

.themeButtons {
	display: flex;
	align-items: center;
	justify-content: end;
	margin-top: auto;
	flex-wrap: wrap;
	gap: 20px;
	margin-top: 20px;
	position: absolute;
	bottom: 20px;
	right: 20px;
}

.themeButtons__timer {
	border: 1px solid #e1e1e1;
	padding: 9px;
	border-radius: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.42857;
}
