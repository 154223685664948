.pageContent {
	background-color: var(--color-white);
	color: var(--color-black);
	width: 100%;
	border-radius: 24px;
	padding: 15px 25px;
}

.pageContent__header {
	display: flex;
	border-bottom: 1px solid var(--color-very-light-grey);
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.pageContent__body {
	display: flex;
	flex-direction: column;
	height: calc(100% - 56px - 15px);
	overflow: hidden;
}

.pageContent__body-title {
	margin-bottom: 15px;
}

/* conversations--list */
.conversations__list {
	display: grid;
	gap: 15px;
}

.conversations__list--scroll {
	overflow-y: auto;
	padding-right: 10px;
}

.conversations__list--scroll::-webkit-scrollbar {
	width: 5px;
}

.conversations__list--scroll::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2); */
	background-color: var(--color-very-light-grey);
	border-radius: 5px;
}

.conversations__list--scroll::-webkit-scrollbar-thumb {
	background-color: var(--color-grey);
	border-radius: 5px;
}

.conversations__item {
	background-color: #f7f7f7;
	padding: 15px 25px;
	border-radius: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 40px;
}

.conversations__item_old {
	opacity: 25%;
}

.conversations__item-details {
	display: grid;
	gap: 10px;
	max-width: calc(100% - 250px);
}

.conversations__item-title {
	margin-bottom: 0;
	font-weight: 500;
	max-width: 100ch;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.conversations__item-date {
	font-size: 14px;
	font-weight: 600;
	color: #9e9e9e;
}

.conversations__item-note {
	font-size: 14px;
}

.conversations__item-actions {
	display: flex;
	align-items: center;
	gap: 8px;
	flex-wrap: wrap;
}

.participants_list_row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
	gap: 8px;
}

/* conversations--details */
.conversations--details .conversations__item {
	align-items: flex-start;
}

.conversations--details .conversations__item-details {
	max-width: calc(100% - 320px);
}

.conversations__item-questions {
	width: 280px;
}

.conversations__item-questions ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	gap: 15px;
}

.conversations__item-questions ul li {
	padding-left: 23px;
	font-weight: 600;
	font-size: 16px;
	line-height: 1.25;
	color: var(--color-grey);
}

.conversations__item-questions ul li.active {
	background: url(../../../public/assets/images/active_arrow.svg) no-repeat left center;
	color: var(--color-yellow);
}

.conversations__item-questions ul li.completed {
	color: var(--color-black);
}

.conversations__columns {
	display: flex;
	column-gap: 15px;
	margin-top: 15px;
	height: calc(100% - 24px - 15px - 120px - 15px);
}

.conversations__column {
	background-color: #f7f7f7;
	border-radius: 16px;
}

.conversations__column--timeline {
	padding: 10px 0;
	width: 280px;
	flex-shrink: 0;
}

.conversations__column--timeline ul {
	display: grid;
	gap: 15px;
	list-style: none;
	padding: 0;
	margin: 0;
}

.conversations__column--timeline ul li {
	font-weight: 600;
	font-size: 15px;
	line-height: 1.28571;
	padding: 13px 13px 13px 33px;
	background-color: var(--color-very-light-grey);
	position: relative;
}

.conversations__column--timeline ul li::before {
	content: "";
	width: 10px;
	height: 10px;
	background-color: #d9d9d9;
	display: block;
	border-radius: 50%;
	position: absolute;
	left: 13px;
	top: 17px;
	z-index: 2;
}

/* .conversations__column--timeline ul li:nth-child(even) {
	padding-left: 62px;
} */

.conversations__column--timeline ul li:nth-child(even):before {
	content: '\2B9A';
	/* margin-left: 25px; */
	background-color: unset;
	top: 40%;
	transform: translateY(-50%);
}

.conversations__column--timeline ul li:nth-child(even).active::before,
.conversations__column--timeline ul li:nth-child(even).completed::before {
	background-color: unset;
}

.conversations__column--timeline ul li::after {
	content: "";
	display: block;
	width: 1px;
	height: 140%;
	position: absolute;
	left: 17px;
	top: 50%;
	z-index: 1;
	background-color: #d9d9d9;
}

.conversations__column--timeline ul li:last-child::after {
	display: none;
}

.conversations__column--timeline ul li.active {
	background-color: var(--color-yellow);
}

.conversations__column--timeline ul li.active::before,
.conversations__column--timeline ul li.completed::before {
	background-color: var(--color-black);
}

.conversations__column--timeline ul li.active::after,
.conversations__column--timeline ul li.completed::after {
	background-color: var(--color-black);
}

.conversations__column--timeline ul li.completed {
	background-color: var(--color-grey);
}

.conversations__column--activity {
	flex-grow: 1;
	border: 1px solid var(--color-yellow);
	padding: 20px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
}

.conversations__column--participants {
	width: 350px;
	flex-shrink: 0;
	padding: 20px;
	position: relative;
	overflow: hidden;
}

.participants__title {
	margin-bottom: 0px;
	font-size: 15px;
}

.participants__count {
	/* position: absolute;
	right: 20px;
	top: 20px; */
	font-weight: 600;
}

.participants__list {
	height: calc(100% - 20px - 15px);
	overflow-y: auto;
	padding-right: 10px;
}

.participants__list::-webkit-scrollbar {
	width: 5px;
}

.participants__list::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2); */
	background-color: var(--color-very-light-grey);
	border-radius: 5px;
}

.participants__list::-webkit-scrollbar-thumb {
	background-color: var(--color-grey);
	border-radius: 5px;
}

.participants__list ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	gap: 20px;
}

.participants__list .participants__item {
	font-size: 14px;
	line-height: 1.42857;
	padding-left: 30px;
}

.participants__list .participants__item>span {
	display: block;
}

.participants__list .participants__item.active {
	background: url(../../../public/assets/images/check_circle.svg) no-repeat left 2px;
}

.participants__list .participants__item .participants__name {
	font-weight: 600;
}

.activity_inner_block {
	overflow-y: auto;
	margin-bottom: 65px;
}

.questionType {
	text-transform: capitalize;
}

.flexBox {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 15px;
}

.conversation_form {
	padding-top: 15px;
}

.conversation_form textarea {
	resize: none;
}

.conversation_form .form__label {
	font-weight: bold;
}

.modal__title {
	font-size: 20px;
}

.date__row fieldset {
	border-color: var(--color-very-light-grey)
}

.date__row fieldset:hover {
	border-color: var(--color-very-light-grey)
}

.date_row .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: var(--color-very-light-grey)
}

.error_msg_text {
	color: red;
}

.open__sidebar {
	display: none;
}

.loadData_loader {
	padding: 30px 0px;
}

.delete__loader {
	width: 25px;
	height: 25px;
	border: 3px solid #FFF;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.export_loading_btn {
	padding: 7px 10px 3px 10px !important;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* alert box admin css */
/* .alertText{
		color: var(--color-dark-grey);
		margin: 30px 0px;
	}

	.alert-modal .ant-modal-footer .ant-btn-primary {
		background-color: var(--color-yellow) !important;
		border-color: var(--color-yellow) !important;
		color: var(--color-black) !important;
		font-weight: 500 !important;
	  }

	  .alert-modal .ant-modal-footer .ant-btn-primary:hover {
		background-color: var(--color-yellow-dark) !important;
		color: var(--color-black) !important;
	}
	  

	  .alert-modal .ant-modal-footer .ant-btn {
		background-color: var(--color-grey);
		border-color: var(--color-grey);
		color: var(--color-black);
		font-weight: 500;
	  }

	  .alert-modal .ant-modal-footer .ant-btn:hover {
		background-color: var(--color-light-grey);
		border-color: var(--color-light-grey);
		color: var(--color-black);
		font-weight: 500;
	  } */
/* alert box admin css end  */

@media screen and (max-width: 1300px) {
	.conversations__column--participants {
		width: 265px
	}

	.conversations__column--timeline {
		width: 230px;
	}
}

@media screen and (max-width: 1200px) {
	.conversations__column--activity {
		order: 3;
	}

	.conversations__column--timeline {
		width: 48%;
	}

	.conversations__column--participants {
		width: 48%;
	}

	.conversations__columns {
		flex-wrap: wrap;
		gap: 15px;
	}

	.pageContent__body {
		overflow-y: auto;
	}
}

@media screen and (max-width: 1000px) {
	.conversations--details .conversations__item {
		display: block;
	}

	.conversations--details .conversations__item-details {
		max-width: 100%;
	}

	.conversations__item-questions {
		margin-top: 20px;
	}
}

@media screen and (max-width: 800px) {
	.conversations__item {
		display: block;
	}

	.conversations__item-details {
		max-width: 100%;
		margin-bottom: 10px;
	}

	.open__sidebar {
		display: flex;
		align-items: center;
		margin-left: 15px;
	}
}

@media screen and (max-width: 600px) {

	.conversations__column--timeline,
	.conversations__column--participants {
		width: 100%;
	}

}

@media screen and (max-width: 450px) {
	.pageContent__header {
		display: block;
	}

	.pageContent {
		padding: 15px 10px;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
	.conversations__column--timeline ul li::after {
		height: 172%;
	}
}

@media screen and (min-width: 800px) and (max-width: 822px) {
	.conversations__column--timeline ul li::after {
		height: 172%;
	}
}

.conversations__column--timeline {
	overflow-y: auto;
}

.conversations__column--timeline::-webkit-scrollbar {
	width: 7px;
}

.conversations__column--timeline::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(202, 199, 199) !important;
	border-radius: 10px;
}

.conversations__column--timeline::-webkit-scrollbar-thumb {
	background: rgb(197, 196, 196);
	border-radius: 10px;
}

.conversations__column--timeline::-webkit-scrollbar-thumb:hover {
	background: #918e8e;
}

.section_number_title {
	margin: 15px 0 -5px 10px;
}

.conversations__column--timeline>ul>li {
	cursor: pointer !important;

}