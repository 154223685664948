.profile-dropdown {
	display: flex;
	align-items: center;
	gap: 15px;
    margin-left: auto;
}

.profile-dropdown__name {
    font-weight: 500;
}
.pageContent__header_inner{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.backIconBox{
	align-items: center;
    display: flex;
    gap: 10px;
	cursor: pointer;
	color: var(--color-white);
}
.backIconBox__main{
	background-color: var(--color-lime);
	border-radius: 8px;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: 600;
}
.backIconBox__main:hover{
	background-color: var(--color-green);
}
.logout_box{
	display: flex;
	align-items: center;
	gap: 10px;
	/* border: 1px solid var(--color-yellow); */
	padding: 5px;
	cursor: pointer;
}
.logout_box span{
	font-weight: bold;
}
.open__sidebar{
	display: none;
}

@media screen and (max-width: 800px) {
	.open__sidebar{
		display: flex;
		align-items: center;
		margin-left: 15px;
	}
}

@media screen and (max-width: 450px) {
	.profile-dropdown{
		margin-left: 0px;
		margin-top: 10px;
	}
}
