/* common css */
.innerPage {
	height: 100vh;
	background-color: var(--color-very-dark-grey);
	color: var(--color-white);
	padding: 15px;
}

.innerPage--sidebar {
	display: flex;
	column-gap: 15px;
}

/* headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-weight: 600;
	line-height: 1.2;
	margin: 0 0 20px;
}

h1,
.h1 {
	font-size: 36px;
}
h2,
.h2 {
	font-size: 30px;
}
h3,
.h3 {
	font-size: 24px;
}
h4,
.h4 {
	font-size: 20px;
}
h5,
.h5 {
	font-size: 18px;
}
h6,
.h6 {
	font-size: 16px;
}

/* buttons */
.button {
	background-color: var(--color-very-dark-grey);
	color: var(--color-white);
	font-weight: 600;
	font-family: "Inter", sans-serif;
	font-size: 18px;
	line-height: 1.222222;
	border: 0;
	border-radius: 8px;
	cursor: pointer;
	padding: 17px 20px;
	min-width: 160px;
	transition: all 0.25s ease-out;
	height: auto;
	white-space: nowrap;
}

.button:disabled {
	opacity: 0.5;
	cursor: wait;
}

.button:hover {
	background-color: var(--color-yellow);
	color: var(--color-white);
}

.ant-btn.button:hover {
	color: var(--color-white) !important;
}

.button--block {
	width: 100%;
}

.button--small {
	min-width: inherit;
	font-size: 14px;
	line-height: 1.42857;
	padding: 10px 15px;
}
.button--very--small {
	min-width: inherit;
	font-size: 14px;
	line-height: 1.42857;
	padding: 7px 15px;
}


.button--green {
	background-color: var(--color-lime);
}

.button--green:hover {
	background-color: var(--color-green);
}

.button--gold {
	background-color: var(--color-yellow);
	color: var(--color-black);
}
.button--gold:disabled{
	cursor: none;
	background-color: lightgray;
}
.button--gold:disabled:hover{
	cursor: none;
	background-color: lightgray;
}

.button--gold:hover {
	background-color: var(--color-yellow-dark) !important;
	color: var(--color-black) !important;
}
.button--assign{
	display: flex;
	align-items: center;
	gap: 8px;
}

.button--have-icon {
	display: flex;
	align-items: center;
	gap: 10px;
}

.button--have-icon svg {
	transition: all 0.25s ease-out;
}

.button--have-icon:hover svg {
	transform: translateX(5px);
}

.button--icon-only {
	background-color: #d9d9d9;
	min-width: inherit;
	padding: 6px;
}

.button--icon-only svg {
	display: block;
}

.button--icon-only:hover {
	background-color: var(--color-grey);
}

@media screen and (max-width: 800px) {
    .innerPage--sidebar{
        display: block;
    }
	.innerPage{
        height: 100%;
    }
}
