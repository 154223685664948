.login__section {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background-color: var(--color-very-light-grey);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
}

.login {
	width: 100%;
	max-width: 580px;
	border-radius: 16px;
	overflow: hidden;
	background-color: var(--color-white);
	font-size: 18px;
}

.login__logo {
	background-color: var(--color-yellow);
	text-align: center;
	padding: 10px 20px;
}

.login__logo .logo {
	margin: 0 auto;
}

.login__form {
	padding: 35px;
}

.login__form-title {
	margin-top: 0;
	text-align: center;
	color: var(--color-dark-grey);
}

.login__create {
	border-top: 1px solid var(--color-very-light-grey);
	padding: 25px 20px;
	text-align: center;
	color: var(--color-dark-grey);
}

.login__create a {
	color: var(--color-black);
}

.login__create a:hover {
	text-decoration: none;
	color: var(--color-yellow);
}

.note-msg {
	font-size: 14px;
	margin-bottom: 0;
}

.login .msg_box p {
	/* margin: 0px; */
}

.login .text-center {
	text-align: center;
}

.login .error-msg {
	color: var(--color-red);
	/* padding-bottom: 10px; */
}

.login .success-msg {
	color: var(--color-green);
	/* font-weight: bold; */
	/* padding-bottom: 10px; */
}

.login .linkText {
	color: var(--color-black);
	font-weight: 600;
	margin-bottom: 30px !important;
	margin-top: 15px !important;
	/* margin-top: 30px; */
}

.login .button--block2 {
	margin-top: 15px;
	max-width: 250px;
	min-width: 200px;
	padding: 10px !important;
	background-color: var(--color-yellow);
	margin-top: 0px;
}

.login .button--block2:hover {
	background-color: var(--color-very-dark-grey);
}

.login .btn_block_3 {
	display: flex;
	gap: 30px;
	justify-content: center;
	padding-top: 20px;
}

.login .logout_btn {
	width: auto;
}
