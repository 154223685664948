:root {
	--color-white: #ffffff;
	--color-black: #1a1b1b;
	--color-very-dark-grey: #3e3e40;
	--color-dark-grey: #58595b;
	--color-grey: #bcbdbd;
	--color-light-grey: #c9caca;
	--color-very-light-grey: #eeeeee;
	--color-yellow: #f5c713;
	--color-yellow-dark: #ddb311;
	--color-red: #ff0000;
	--color-green: #008000;
	--color-lime: #2fd153;
}

*,
::after,
::before {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.2;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

a {
	transition: all 0.2s ease-out;
}
