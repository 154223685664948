.form__row {
	margin-bottom: 20px;
}

.form__row:last-child {
	margin-bottom: 0;
}

.form__row--2column {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form__label {
	display: block;
	margin-bottom: 10px;
	color: var(--color-dark-grey);
}

.form__input {
	width: 100%;
	border-radius: 8px;
	font-size: 16px;
	border: 1px solid var(--color-very-light-grey);
	color: var(--color-black);
	background-color: var(--color-white);
	padding: 18px 20px;
}

.form__input:focus {
	outline: none;
}

.form__column-inline {
	display: flex;
	align-items: center;
}

.form__checkbox {
	width: 24px;
	height: 24px;
	margin: 0 10px 0 0;
}

.form__column-inline .form__label {
	margin-bottom: 0;
}

.form__column--checkbox .form__label {
	font-weight: 500;
	font-size: 16px;
}

.form__column--forgot {
	color: var(--color-dark-grey);
	font-size: 16px;
	font-weight: 700;
}

.form__column--forgot a {
	color: var(--color-dark-grey);
}

.form__column--forgot a:hover {
	text-decoration: none;
	color: var(--color-yellow);
}

.form__row--submit {
	margin-top: 40px;
	text-align: center;
}
